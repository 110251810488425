let origin = window.ORIGIN;
// 师生介绍列表
const tsIntroductionList = `${origin}/gateway/hc-health/miniapi/seniumUniversity/tsIntroductionList`;
// 优秀作品列表
const goodWorksList = `${origin}/gateway/hc-health/miniapi/seniumUniversity/goodWorksList`;
// 优秀作品详情
const goodWorksDetails = `${origin}/gateway/hc-health/miniapi/seniumUniversity/goodWorksDetails`;
// 浏览数加一
const addReadCount = `${origin}/gateway/hc-health/miniapi/seniumUniversity/addReadCount`;
// 课程预约列表
const classAppointmentList = `${origin}/gateway/hc-health/miniapi/seniumUniversity/classAppointmentList`;
// 选择课程列表
const choiceClassList = `${origin}/gateway/hc-health/miniapi/seniumUniversity/choiceClassList`;
// 选择课程详情
const choiceClassDetails = `${origin}/gateway/hc-health/miniapi/seniumUniversity/choiceClassDetails`;
// 课程预约
const appointmentClass = `${origin}/gateway/hc-health/miniapi/seniumUniversity/appointmentClass`;
// 取消预约
const cancelAppointment = `${origin}/gateway/hc-health/miniapi/seniumUniversity/cancelAppointment`;
// 我的预约列表
const myAppointmentList = `${origin}/gateway/hc-health/miniapi/seniumUniversity/myAppointmentList`;
// 预约详情
const myAppointmentDetails = `${origin}/gateway/hc-health/miniapi/seniumUniversity/myAppointmentDetails`;
// 课程签到
const classSignIn = `${origin}/gateway/hc-health/miniapi/seniumUniversity/classSignIn`;
export {
  tsIntroductionList,
  goodWorksList,
  goodWorksDetails,
  addReadCount,
  classAppointmentList,
  choiceClassList,
  choiceClassDetails,
  appointmentClass,
  cancelAppointment,
  myAppointmentList,
  myAppointmentDetails,
  classSignIn,
};
