<template>
  <div class="page">
    <!-- <div class="opt-item" @click="showDate = true">
      <div class="label">选择日期</div>
      <div class="date">{{ date || "请选择日期" }}</div>
      <img src="./img/date.png" class="dateIcon" />
    </div> -->
    <!-- <div class="opt-item" v-if="date" @click="show = true"> -->
    <div class="opt-item">
      <div class="label">选择课程</div>
      <div class="className">{{ className || "请选择课程" }}</div>
      <!-- <img src="./img/down-arrow.png" class="arrowIcon" /> -->
    </div>
    <div class="opt-item" v-if="className">
      <div class="label">基本信息</div>
      <!-- <div class="lastNumStr">剩余可预约量 {{ info.appointmentsNumber }}</div> -->
    </div>
    <div class="opt-item" v-if="className">
      <div class="time">场次时间：{{ info.time }}</div>
    </div>
    <div class="opt-item" v-if="className">
      <div class="place">课程地点：{{ info.classAddress }}</div>
    </div>
    <!-- <div class="btn" v-if="classId" @click="submit()">确认预约</div> -->
    <v-dateTimePicker
      type="date"
      :value="date"
      :minDate="minDate"
      :valueShow.sync="showDate"
      :isAuto="true"
      @confirmPicker="confirmDate"
    ></v-dateTimePicker>
    <v-picker
      :columns="classList"
      :valueShow="show"
      valueKey="className"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
  </div>
</template>

<script>
var moment = require("moment");
import {
  choiceClassList,
  choiceClassDetails,
  appointmentClass,
} from "./api.js";
export default {
  data() {
    return {
      show: false,
      showDate: false,
      date: null,
      className: null,
      classId: null,
      classList: [],
      info: {},
      minDate: null,
      classDetail: null,
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
  },
  created() {
    this.classDetail = JSON.parse(this.$route.query.dataStr);
    this.className = this.classDetail.className;
    this.classId = this.classDetail.classId;
    console.log(this.classDetail);
    let date = new Date();
    let timestamp = date.setDate(date.getDate());
    this.minDate = new Date(parseInt(timestamp));
    this.date = moment(new Date(parseInt(timestamp))).format("YYYY-MM-DD");
    // this.getClassList();
    this.getInfo();
  },
  methods: {
    submit() {
      let params = {
        userId: this.userId,
        classId: this.classId,
        week: this.classDetail.week,
      };
      this.$axios.post(`${appointmentClass}`, params).then((res) => {
        if (res.code == 200) {
          this.$router.replace({
            name: "appointmentSucceeded",
          });
        }
      });
    },
    getInfo() {
      let params = {
        classId: this.classId,
      };
      this.$axios
        .get(`${choiceClassDetails}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.info = res.data;
          }
        });
    },
    getClassList() {
      let params = {
        date: this.date,
      };
      this.$axios.get(`${choiceClassList}`, { params: params }).then((res) => {
        if (res.code == 200) {
          // this.className = null;
          this.classList = res.data;
        }
      });
    },
    confirmDate(val) {
      this.date = moment(val).format("YYYY-MM-DD");
      this.getClassList();
    },
    clickOverlay() {
      this.show = false;
    },
    cancelPick() {
      this.show = false;
    },
    confirm(value) {
      this.className = value.className;
      this.classId = value.classId;
      let params = {
        classId: this.classId,
        date: this.date,
      };
      this.$axios
        .get(`${choiceClassDetails}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.info = res.data;
          }
        });
      this.show = false;
    },
    pickDate() {
      this.showStart = true;
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 42px 30px 0;
  .opt-item {
    width: 100%;
    height: 90px;
    background: #f9f9f9;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    box-sizing: border-box;
    padding: 0 30px;
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    .dateIcon {
      width: 26px;
      height: 26px;
    }
    .arrowIcon {
      width: 18px;
      height: 12px;
    }
    .lastNumStr,
    .time,
    .place {
      text-align: right;
      flex: 1;
    }
    .date,
    .className {
      flex: 1;
      text-align: right;
      padding-right: 22px;
      box-sizing: border-box;
    }
    .label {
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .btn {
    text-align: center;
    line-height: 74px;
    width: 620px;
    height: 74px;
    background: #93ccf1;
    border-radius: 10px;
    font-size: 34px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
  }
}
</style>